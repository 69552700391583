import { Box, Button, Divider, Stack, ThemeProvider, Tooltip, Typography, createTheme } from "@mui/material";


export default function App() {
  const theme = createTheme({

  })
  return (
    <div className="App">
      <ThemeProvider theme={theme}>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: "100vh",
            maxWidth: "100%",
            maxHeight: "100%",
          }}
        >
          <header>
            <Typography variant="h2" align="center">
              MOONSHOT ANTIBODIES
            </Typography>
            <Typography variant="subtitle2" gutterBottom align="center">
            A new strategy to treat cancer with antibodies.
            </Typography>
            <Divider />
          </header>
          <Stack 
            sx={{
              width: "40%",
              marginTop: "15px",
              gap: "10px"
            }}
            alignContent={"center"}
          >
            <Typography 
              variant="body2"
              gutterBottom
            >
            All cancers have mutations.
            </Typography>
            <Typography 
              variant="body2"
              gutterBottom 
            >
            More than 99% of antibody-targetable mutations in a cancer are unique to that one patient.
            </Typography>
            <Typography 
              variant="body2"
              gutterBottom 
            >
            Why not make antibodies for that patient?
            </Typography>
            <Typography 
              variant="body2" 
              gutterBottom
            >
            Moonshot Antibodies is dedicated to making antibodies special for each patient to target their unique set of mutations.
            </Typography>  
          </Stack>
          <Tooltip
            arrow
            followCursor
            enterDelay={600}
            title={"E-mail Moonshot Antibodies"}
            PopperProps={{
              sx: {
                padding: "15px"
              }
            }}
          >
            <Button 
              variant="contained"
              target="_top"
              size="large"
              rel="noopener noreferrer"
              href={`mailto:David.Krag@moonshotab.com`}
              sx={{
                marginTop:"10px"
              }}
            >
              SAY HELLO 👋
            </Button>
          </Tooltip>
        </Box>
      </ThemeProvider>
    </div>
  );
}
